import React, { useRef, useEffect, useState, FC } from 'react';
import { GOOGLE_MAPS_API } from '@/utils/constants';
import { useMap } from '@vis.gl/react-google-maps';

export interface AddressComponents {
  address: string;
  addressComponents: google.maps.GeocoderAddressComponent[];
}

interface Props {
  className?: string;
  addressFromInput: (address: AddressComponents) => void;
}

export const PlaceAutocompleteInput: FC<Props> = ({
  className,
  addressFromInput,
}) => {
  const map = useMap();
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null);

  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);
  const [address, setAddress] = useState<AddressComponents>();
  const twClass =
    className ||
    'inline-block h-full w-full border border-x-0 border-gray-200 px-4 py-1 text-sm focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none';

  useEffect(() => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API}&loading=async&libraries=places-new`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        setAutocompleteService(new google.maps.places.AutocompleteService());
      };
    } else {
      setAutocompleteService(new google.maps.places.AutocompleteService());
    }
  }, []);

  useEffect(() => {
    if (map) {
      setPlacesService(new google.maps.places.PlacesService(map));
    }
  }, [map]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!autocompleteService || !inputRef.current) return;

    const request = {
      input: event.target.value,
      componentRestrictions: { country: ['us', 'ca'] },
    };

    autocompleteService.getPlacePredictions(request, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
        setPredictions(predictions);
      }
    });
  };

  const handleSelect = (
    prediction: google.maps.places.AutocompletePrediction
  ) => {
    handlePlaceSelect(prediction.place_id);
    inputRef.current!.value = prediction.description;
    setShowDropdown(false);
    setSelectedIndex(-1);
  };

  const handlePlaceSelect = (placeId: string) => {
    if (!placesService) return;

    placesService.getDetails({ placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place) {
        const address: AddressComponents = {
          address: place.formatted_address || '',
          addressComponents: place.address_components || [],
        };
        setAddress(address);
        addressFromInput(address);
      }
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!predictions.length) return;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedIndex((prev) =>
        prev < predictions.length - 1 ? prev + 1 : prev
      );
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
    }

    if (e.key === 'Enter' && selectedIndex !== -1) {
      e.preventDefault();
      handleSelect(predictions[selectedIndex]);
      inputRef.current!.value = predictions[selectedIndex].description;
      setSelectedIndex(-1);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        type='text'
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setShowDropdown(true)}
        onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
        className={twClass}
      />
      {showDropdown && predictions.length > 0 && (
        <ul
          ref={listRef}
          className='absolute top-full z-50 w-full border border-gray-200 bg-white shadow-lg'
        >
          {predictions.map((prediction, idx) => (
            <li
              key={prediction.place_id}
              onClick={() => handleSelect(prediction)}
              onMouseEnter={() => setSelectedIndex(idx)}
              className={`cursor-pointer p-2 ${
                selectedIndex === idx ? 'bg-gray-200' : 'hover:bg-gray-100'
              }`}
            >
              {prediction.description}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
