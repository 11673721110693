import React, { useEffect, useRef } from 'react';
import { InfoWindow, useMap } from '@vis.gl/react-google-maps';
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer';
import { GeoLocation, MapData } from '../type';
import FormatValue from 'utils/FormatValue';
import { RenderHTML } from 'components/renderHtml';
import { useStore } from 'zustand';
import { useMapStore } from '@/stores/map/mapStore';
import { MAP_INITIAL_STATE } from '@/utils/constants';

const ClusteredMarkers = () => {
  const { data, yourLocation, hasSetLocation, activeMarker, setActiveMarker } =
    useStore(useMapStore, (state) => ({
      data: state.data,
      yourLocation: state.yourLocation,
      hasSetLocation: state.hasSetLocation,
      activeMarker: state.activeMarker,
      setActiveMarker: state.setActiveMarker,
    }));

  const handlePinClick = (location: MapData) => {
    const position: GeoLocation = {
      lat: location.attributes.latitude,
      lng: location.attributes.longtitude,
    };

    position.lat &&
      position.lng &&
      setActiveMarker({
        position,
        label: String(location.attributes.title),
        info: location.attributes,
        isDispatch: !!!location.dispatch,
      });
  };

  const handleCloseInfoWindow = () => {
    setActiveMarker(null);
  };

  const clustererRef = useRef<MarkerClusterer | null>(null);
  const map = useMap();
  const initMap = async () => {
    const addCustomMarker = (
      coords: GeoLocation,
      content: HTMLDivElement | HTMLElement
    ) => {
      new google.maps.marker.AdvancedMarkerElement({
        position: coords,
        map,
        content,
      });
    };

    const generateClusterMarkers = (
      data: MapData[],
      markerData: {
        glyphColor: string;
        borderColor: string;
        background: string;
      }
    ): google.maps.marker.AdvancedMarkerElement[] => {
      return data.map((marker) => {
        const pinGlyph = new google.maps.marker.PinElement(markerData);
        const mapPin = new google.maps.marker.AdvancedMarkerElement({
          position: {
            lat: marker.attributes.latitude,
            lng: marker.attributes.longtitude,
          },
          content: pinGlyph.element,
        });

        mapPin.addListener('gmp-click', () => handlePinClick(marker));

        return mapPin;
      });
    };

    clustererRef.current?.clearMarkers();

    const insideMarkers = generateClusterMarkers(data.radialData.inside, {
      glyphColor: '#0049BB',
      borderColor: '#0071E3',
      background: '#0071E3',
    });

    clustererRef.current = new MarkerClusterer({
      algorithm: new SuperClusterAlgorithm({ maxZoom: 10, radius: 80 }),
      map,
      markers: [...insideMarkers],
    });

    if (hasSetLocation()) {
      const userPinGlyph = new google.maps.marker.PinElement({
        glyphColor: '#FFFFFF',
        borderColor: '#FF0000',
        background: '#FF0000',
      });

      addCustomMarker(yourLocation, userPinGlyph.element);
    }

    const mrcoolHQSVG = `
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <circle cx="20" cy="20" r="18" fill="#FF5733" stroke="#333" stroke-width="2" />
        <text x="20" y="25" font-size="14" font-family="Arial" fill="#FFF" text-anchor="middle">
          M
        </text>
      </svg>
    `;
    const customMarkerDiv = document.createElement('div');
    customMarkerDiv.innerHTML = mrcoolHQSVG;

    addCustomMarker(MAP_INITIAL_STATE.mapCenter.geometry, customMarkerDiv);

    return insideMarkers;
  };

  useEffect(() => {
    const loadMarkers = async () => {
      await initMap();
    };

    loadMarkers();
  }, [data.radialData.inside]);

  return (
    <>
      {activeMarker && (
        <InfoWindow
          position={activeMarker.position}
          onCloseClick={handleCloseInfoWindow}
        >
          <div className='mrc-map-info-marker pb-5 text-left sm:px-3'>
            <div className='flex flex-col gap-4 text-left text-xs tracking-wide sm:text-sm'>
              <h1 className='font-bold'>{activeMarker.info.title}</h1>

              {activeMarker.isDispatch && (
                <div>
                  {activeMarker.info?.address} {activeMarker.info?.street_1}{' '}
                  {activeMarker.info?.city} {activeMarker.info?.state}{' '}
                  {activeMarker.info?.postal_code}
                </div>
              )}

              {!activeMarker.isDispatch && (
                <div>{activeMarker.info.address}</div>
              )}

              <div className='text-sm text-primary'>
                <RenderHTML
                  suppressHydrationWarning
                  className='text-sm text-primary'
                  data={activeMarker.info.description as string}
                />

                {activeMarker.info?.phone && (
                  <div className='flex items-center gap-1'>
                    <span>Phone:</span>
                    <a href={`tel:${activeMarker.info.phone}`}>
                      {FormatValue.phoneNumber(
                        parseInt(activeMarker.info.phone)
                      )}
                    </a>
                  </div>
                )}

                {activeMarker.info?.email && (
                  <div className='flex items-center gap-1'>
                    <span>Email:</span>
                    <a href={`mailto:${activeMarker.info.email}`}>
                      {activeMarker.info.email}
                    </a>
                  </div>
                )}

                {activeMarker.info?.website && (
                  <div className='flex items-center gap-1'>
                    <span>Web:</span>
                    <a
                      target='_blank'
                      href={`https://${FormatValue.removeUrlProtocol(
                        activeMarker.info.website
                      )}`}
                    >
                      {FormatValue.removeUrlProtocol(activeMarker.info.website)}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default ClusteredMarkers;
