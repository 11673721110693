import { Button } from 'components/button';
import { AddressProps as ImportedAddressProps } from 'components/map/type';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AxiosInstance, { AxiosRequestConfig } from 'axios';
import ModalComponent from 'components/modal/ModalComponent';
import Heading from 'components/common/Heading';
import { MdCheckCircleOutline, MdClose } from 'react-icons/md';
import { twMerge } from 'tailwind-merge';
import 'react-phone-input-2/lib/style.css';
import { OptionTypes } from 'types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { GmapPlaceAutocomplete } from 'components/map/component/autocomplete';

type CountryType = 'United States' | 'Canada' | string;
type ContactType = 'mobile' | 'phone' | 'email' | string;

export interface MatchJobAddressTemplate {
  street_1: string;
  street_2?: string;
  city: string;
  state: string;
  postal_code: string;
  country: CountryType;
}

interface ContactMethod {
  value: string;
  method: ContactType;
  notify: boolean;
}
interface MatchJobContact {
  first_name: string;
  last_name: string;
  primary: boolean;
  prefered_communication: ContactType;
  contact_methods: ContactMethod[];
}

interface MatchJobCustomerRecord {
  external_id?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_numbers: {
    number: string;
    primary?: boolean;
    type: ContactType;
  }[];
  home_address: MatchJobAddressTemplate;
}

interface MatchJobOfferRecord {
  organization: number[];
  job: {
    title: string;
    description: string;
    external_id: string;
    address: MatchJobAddressTemplate;
    contacts?: MatchJobContact[];
    service_type: string[];
  };
  customer: MatchJobCustomerRecord;
}

type Props = {
  items: any;
  isOpen: boolean;
  onClose: () => void;
};

type FormValues = {
  external_id?: string | any;
  title?: string | any;
  description?: string | any;
  contacts: {
    first_name?: string | any;
    last_name?: string | any;
    primary?: boolean | true;
    prefered_communication?: string | any;
    contact_method?: any[] | any;
  };
  first_name?: string | any;
  last_name?: string | any;
  address: ImportedAddressProps;
  email: string | any;
  phoneType: any;
  tel: string | any;
  service_type: OptionTypes[] | any[] | any;
};

const convertToMatchJobAddress = (
  address: ImportedAddressProps
): MatchJobAddressTemplate => {
  if (!address) {
    throw new Error('Address is required');
  }

  return {
    street_1: address.street_1 || '',
    street_2: address.street_2 || '',
    city: address.city || '',
    state: address.state || '',
    postal_code: address.postal_code || '',
    country: address.country || 'United States',
  };
};

export const WorkOrderForm: FC<Props> = ({ items, isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isMessage, setIsMessage] = useState<string | undefined>();
  const [isMessageOpen, setIsMessageOpen] = useState<boolean>(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        external_id: '',
        title: '',
        description: items?.job?.description || '',
        contacts: {
          first_name: '',
          last_name: '',
          primary: true,
          prefered_communication: '',
          contact_method: [],
        },
        first_name: '',
        last_name: '',
        address: {
          street_1: '',
          street_2: '',
          city: '',
          state: '',
          postal_code: '',
          country: '',
        },
        email: '',
        phone: '',
        service_type: [],
      }),
      [items]
    ),
  });

  useEffect(() => {
    if (isOpen) {
      reset();
      if (items) {
        setValue('address', items.job.address);
        setValue('tel', items.job.phone);
        setValue('description', items.job.description);
      }
    }
  }, [isOpen, items, reset, setValue]);

  const onSubmit = async (data: FormValues) => {
    if (!data.address?.street_1) {
      return;
    }

    const convertedAddress = convertToMatchJobAddress(data.address);

    const phoneNumber = data.tel.replace(/[^\d]+/g, '');
    const [first, last] = data.first_name.split(' ');
    const defaultEmail = data.email;

    const dispatchDetails: MatchJobOfferRecord[] = [
      {
        organization: [...items.organizationId],
        job: {
          description: data.description,
          external_id: phoneNumber,
          title: `Job Title: ${phoneNumber}`,
          address: convertedAddress,
          contacts: [
            {
              primary: true,
              first_name: first,
              last_name: last || '',
              prefered_communication: 'phone',
              contact_methods: [
                {
                  value: phoneNumber,
                  method: 'phone',
                  notify: true,
                },
              ],
            },
          ],
          service_type: ['order'],
        },
        customer: {
          first_name: first,
          last_name: last || '',
          external_id: phoneNumber,
          email: defaultEmail,
          phone_numbers: [
            {
              number: phoneNumber,
              primary: true,
              type: 'phone',
            },
          ],
          home_address: convertedAddress,
        },
      },
    ];

    await createWorkOrder({ formData: dispatchDetails });
    reset();
  };

  useEffect(() => {
    if (isMessage) {
      setIsMessageOpen(true);
      const timer = setTimeout(() => {
        setIsMessageOpen(false);
        setIsMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isMessage]);

  const axios = AxiosInstance.create({
    baseURL: process.env.API_ENDPOINT,
  });

  let prefix = '/work-orders/dispatch';

  const createWorkOrder = async ({
    formData,
    config,
  }: {
    formData: MatchJobOfferRecord[];
    config?: AxiosRequestConfig;
  }) => {
    setIsLoading(true);
    try {
      const { data, status } = await axios.post(prefix, formData, config);
      if (status == 200) {
        setIsLoading(false);
        setIsError(false);
        onClose();
        setIsMessage('Success! Your request has been successfully submitted.');
      } else {
        setIsLoading(false);
        setIsError(true);
        setIsMessage('Oops! We hit a bump. Please conctact support@mrcool.com');
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setIsMessage('Oops! We hit a bump. Please conctact support@mrcool.com');
    }
  };

  return (
    <>
      <ModalComponent
        className='rounded-[unset] sm:rounded-xl'
        size='lg'
        isOpen={isOpen}
        close={onClose}
      >
        <div className='flex items-center justify-between border-none px-6 py-4'>
          <Heading size={4}>Inquiry - {items?.organization.name}</Heading>
          <button
            className='flex items-center justify-center rounded-lg p-1'
            onClick={onClose}
          >
            <MdClose className='size-4 text-inherit' />
          </button>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex w-full flex-col gap-4'
        >
          <div className='flex w-full flex-col gap-4 px-16 py-10'>
            <div className='flex w-full flex-col'>
              <label htmlFor='first_name' className='font-medium'>
                Contact Name
              </label>
              <input
                type='text'
                {...register('first_name', {
                  required: 'Please enter your name',
                })}
                className={`block w-full rounded-lg border px-4 py-3 ${
                  errors.first_name ? 'border-red-500' : ''
                }`}
                placeholder='Enter your name'
              />
              {errors.first_name && (
                <div className='text-xs text-red-500'>
                  {typeof errors.first_name.message === 'string'
                    ? errors.first_name.message
                    : 'Please enter your name'}
                </div>
              )}
            </div>

            <div className='flex w-full flex-col gap-3'>
              <div className='font-medium'>Address</div>
              <div className='flex w-full flex-col gap-3'>
                <Controller
                  name='address'
                  control={control}
                  rules={{
                    required: 'Please enter a valid address',
                    validate: (value) => {
                      return value?.street_1
                        ? true
                        : 'Please enter a valid address';
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <GmapPlaceAutocomplete
                      value={value?.street_1}
                      onChange={(address) => {
                        address && onChange(address);
                      }}
                      onBlur={onBlur}
                      placeholder='123 South Main Street, Somewhereville, ME 01234'
                      className={`block w-full rounded-lg border px-4 py-3 pe-16 focus:z-10 focus:outline-none ${
                        errors.address ? 'border-red-500' : ''
                      }`}
                      error={Boolean(errors.address)}
                      errorMessage={errors.address?.message as string}
                    />
                  )}
                />
              </div>
            </div>

            <div className='flex w-full flex-col gap-3'>
              <div className='font-medium'>Email</div>
              <div className='flex w-full flex-col gap-3'>
                <input
                  type='text'
                  {...register('email', {
                    required: 'Please provide a valid email',
                    pattern: {
                      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: 'Invalid email address',
                    },
                  })}
                  className={`block w-full rounded-lg border px-4 py-3 ${
                    errors.email ? 'border-red-500' : ''
                  }`}
                  placeholder='someone@me.com'
                />
                {errors.email && (
                  <div className='text-xs text-red-500'>
                    {typeof errors.email.message === 'string'
                      ? errors.email.message
                      : 'Please provide a valid email'}
                  </div>
                )}
              </div>
            </div>

            <div className='flex w-full flex-col gap-3'>
              <div className='font-medium'>Phone</div>
              <div className='flex w-full flex-col gap-1 sm:flex-row'>
                <div className='relative w-full sm:w-4/6'>
                  <Controller
                    name='tel'
                    control={control}
                    rules={{
                      required: 'Phone number is required',
                      validate: (value) => {
                        const sanitizedValue = value.replace(/\D/g, '');
                        return (
                          sanitizedValue.length === 11 ||
                          'Please enter a complete phone number'
                        );
                      },
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <PhoneInput
                        country={'us'}
                        onChange={onChange}
                        preferredCountries={['us']}
                        inputStyle={{
                          width: '100%',
                          height: '45px',
                          borderRadius: '8px',
                          borderColor: errors.tel ? '#ef4444' : '#e5e7eb',
                        }}
                        inputProps={{
                          ref: ref,
                          required: true,
                        }}
                        countryCodeEditable={false}
                        enableSearch={false}
                        autoFormat={true}
                      />
                    )}
                  />
                  {errors?.tel && (
                    <div className='mt-1 text-xs text-red-500'>
                      {errors.tel.message as string}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='flex w-full flex-col gap-3'>
              <label htmlFor='description' className='font-medium'>
                Notes (optional)
              </label>
              <div className='relative flex w-full'>
                <textarea
                  id='notes'
                  rows={2}
                  cols={5}
                  className={`block w-full rounded-lg border px-4 py-3 pe-16 focus:outline-none focus:z-10${errors.description ? ' border-red-500' : ''}`}
                  placeholder='Eg. in need of heating and cooling for a 3 bedroom, 2 story house. 1200 sq ft.'
                  {...register(`description`)}
                />
              </div>
            </div>

            <div className='flex w-full items-center justify-center'>
              <Button
                id='mrc-submit-request'
                type='submit'
                variant='custom'
                className='rounded-full bg-primary px-3 py-2 text-white hover:bg-primary-dark hover:text-white'
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className='text-sm'>
                    Loading..
                    <div
                      className='inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-current border-t-transparent text-white'
                      role='status'
                      aria-label='loading'
                    >
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  'Send Inquiry'
                )}
              </Button>
            </div>
          </div>
        </form>
      </ModalComponent>

      <ModalComponent
        className='rounded-[unset] sm:rounded-xl'
        size='md'
        isOpen={isMessageOpen}
        close={() => setIsMessageOpen(false)}
      >
        <div className='flex items-center justify-end border-none px-6 py-4'>
          <button
            className='flex items-center justify-center rounded-lg border border-gray-200 p-1'
            onClick={() => setIsMessageOpen(false)}
          >
            <MdClose className='size-4 text-inherit' />
          </button>
        </div>

        <div className='mb-10 flex w-full flex-col gap-4'>
          <div className='flex items-center justify-center'>
            <MdCheckCircleOutline
              className={twMerge(isError ? 'hidden' : 'size-20 text-primary')}
            />
          </div>
          <div className='flex flex-col items-center justify-center border-none px-6 text-center'>
            <Heading size={5}>{isMessage || ''}</Heading>
            <p className={twMerge(isError ? 'hidden' : 'text-sm font-medium')}>
              Our team will review and start processing your request shortly.
            </p>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};
