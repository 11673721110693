import { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useStore } from 'zustand';
import { Map, useMap } from '@vis.gl/react-google-maps';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useMapStore } from 'stores/map/mapStore';
import { MAP_ITEMS_PER_PAGE } from 'utils/constants';
import { Button } from 'components/button';
import { WorkOrderForm } from 'components/form/work-order';
import { PartnerCard } from './partner-button';
import ClusteredMarkers from './clustered-markers';
import { DispatchOrganization, GeoLocation, MapData } from '../type';
import './map-plus-sidebar.scss';

interface Props {
  isModal?: 'true' | 'false';
}
export interface FormData {
  organizationId: number[];
  organization: DispatchOrganization;
  job: any;
}

export const MapPlusSidebar: FC<Props> = ({ isModal = 'false' }) => {
  const {
    data,
    hasLoaded,
    mapCenter,
    currentPage,
    zoom,
    getDispatchData,
    getPartnerData,
    setCurrentPage,
    findClosestStore,
    getCurrentPosition,
    setActiveMarker,
    setSelectedDistance,
    hasSetLocation,
  } = useStore(useMapStore, (state) => ({
    data: state.data,
    distanceOptions: state.distanceOptions,
    hasLoaded: state.hasLoaded,
    mapCenter: state.mapCenter,
    currentPage: state.currentPage,
    zoom: state.zoom,
    getDispatchData: state.getDispatchData,
    getPartnerData: state.getPartnerData,
    setCurrentPage: state.setCurrentPage,
    findClosestStore: state.findClosestStore,
    getCurrentPosition: state.getCurrentPosition,
    hasSetLocation: state.hasSetLocation,
    setActiveMarker: state.setActiveMarker,
    setSelectedDistance: state.setSelectedDistance,
  }));

  useEffect(() => {
    getCurrentPosition();
    getPartnerData(0, 500, []);
    getDispatchData();
  }, []);

  const map = useMap();
  const { partner, radialData } = data;
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>();
  const startIndex = (currentPage.page - 1) * MAP_ITEMS_PER_PAGE;
  const endIndex = startIndex + MAP_ITEMS_PER_PAGE;
  const currentItems = useMemo(() => {
    return radialData.inside
      ? radialData.inside.slice(startIndex, endIndex)
      : [];
  }, [radialData.inside, startIndex, endIndex]);
  const totalPages = useMemo(() => {
    return radialData.inside
      ? Math.ceil(radialData.inside.length / MAP_ITEMS_PER_PAGE)
      : 0;
  }, [radialData.inside]);

  const handleNextPage = () => {
    if (currentPage.page < totalPages) {
      setCurrentPage(currentPage.page + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage.page > 1) {
      setCurrentPage(currentPage.page - 1);
    }
  };

  const onChangeOpen = (item: MapData) => {
    if (!item || !item.attributes.organization) return;

    const partnerOrgs = radialData.inside
      .map((partner) => (partner?.dispatch ? partner.id : undefined))
      .filter((id) => id !== undefined)
      .filter((id) => id !== item.id)
      .slice(0, 2);

    setIsOpenForm((open) => !open);

    const data: FormData = {
      organizationId: [item.id, ...partnerOrgs],
      organization: item.attributes.organization,
      job: item.attributes.job,
    };

    setFormData(data);
  };

  const handleMarkerClick = (location: MapData) => {
    const coords: GeoLocation = {
      lat: location.attributes.latitude,
      lng: location.attributes.longtitude,
    };

    setActiveMarker({
      position: coords,
      label: String(location.attributes.title),
      info: location.attributes,
      isDispatch: Boolean(location.dispatch),
    });
  };

  const displayPartnerInfoWindow = (idx: number) => {
    const partnerMapData = radialData.inside[idx];

    if (!partnerMapData) return;

    handleMarkerClick(partnerMapData);
  };

  useEffect(() => {
    zoom && map?.setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    if (hasLoaded.dispatch && hasLoaded.partners) {
      hasLoaded.initialLoad = false;
      const data = findClosestStore(true);

      if (data.inside.length === 0) {
        setSelectedDistance(null);
      }
    }
  }, [hasLoaded]);

  useEffect(() => {
    if (map && mapCenter.geometry) {
      map.panTo(mapCenter.geometry);
    }
  }, [mapCenter.geometry]);

  const modalClasses = `flex flex-col overflow-hidden border sm:flex-row ${isModal === 'true' ? 'rounded-b-3xl' : 'rounded-3xl'}`;

  return (
    <div className={modalClasses}>
      <div
        id='sidemap'
        className='flex h-[calc(100vh-14rem)] max-h-[600px] flex-col overflow-y-auto'
      >
        <div className='sticky top-0 w-full border-b bg-white p-5 text-left'>
          {radialData.inside.length && hasSetLocation() ? (
            <strong>{`${radialData.inside.length} dealer(s) near you`}</strong>
          ) : radialData.inside.length && !hasSetLocation() ? (
            <strong>{`${radialData.inside.length} dealer(s) in all locations`}</strong>
          ) : (
            <strong>
              {!hasLoaded.initialLoad &&
                'To set a radius you must first enter a location'}
            </strong>
          )}
        </div>

        <div className='w-full flex-grow'>
          <div className='flex flex-col divide-y-2'>
            {currentItems &&
              currentItems.map((data, idx) => (
                <div
                  key={data.id}
                  onClick={() => displayPartnerInfoWindow(idx)}
                  className='mrc-locations-btn'
                >
                  <PartnerCard data={data} onChangeOpen={onChangeOpen} />
                </div>
              ))}
          </div>
        </div>

        <div
          className={`sticky bottom-0 mt-auto flex w-full items-center justify-end gap-3 border-t bg-white p-5 ${
            radialData.inside?.length < 5 ? 'hidden' : ''
          }`}
        >
          <Button
            variant='custom'
            color='default'
            className='rounded-full border border-gray-500 p-1 disabled:opacity-30'
            onClick={handlePrevPage}
            disabled={currentPage.page === 1}
          >
            <MdChevronLeft className='size-6 text-inherit' />
          </Button>
          <Button
            variant='custom'
            color='default'
            className='rounded-full border border-gray-500 p-1 disabled:opacity-30'
            onClick={handleNextPage}
            disabled={currentPage.page === totalPages}
          >
            <MdChevronRight className='size-6 text-inherit' />
          </Button>
        </div>
      </div>

      <Map
        className={`h-[calc(100vh-14rem)] max-h-[600px] w-full`}
        mapId={'mrcool_store'}
        defaultCenter={mapCenter.geometry}
        defaultZoom={zoom}
        gestureHandling={'cooperative'}
        streetViewControl={false}
      >
        {partner && <ClusteredMarkers />}
      </Map>
      <WorkOrderForm
        items={formData}
        isOpen={isOpenForm}
        onClose={() => setIsOpenForm(false)}
      />
    </div>
  );
};
