import {
  BuildingFinalResultJSON,
  ResultFormJSON,
  SystemSelectorFormProps,
} from '@/components/form/system-selector/types';

const phoneNumberRegex =
  /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/;

export const isValidPhoneNumber = (phoneNumber: string) => {
  return phoneNumberRegex.test(phoneNumber);
};

export const splitFullName = (
  fullName: string
): { firstName: string; lastName: string } => {
  const nameParts = fullName.trim().split(/\s+/);
  const firstName = nameParts.slice(0, -1).join(' ');
  const lastName = nameParts[nameParts.length - 1];
  return { firstName, lastName };
};

export const convertBytes = ({ bytes, decimals = 2 }: any) => {
  let units = ['KB', 'MB', 'GB', 'TB', 'PB'];

  let i = 0;

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i];
};

export const sortByArr = (fn: any, sortBy: boolean) => {
  function cmp(a: any, b: any) {
    if (sortBy) return -(a < b) || +(a > b);
    else return -(a > b) || +(a < b);
  }
  return function (a: any, b: any) {
    return cmp(fn(a), fn(b));
  };
};

// transform JSON V2
export const resultConfiguratorFormJSON = (
  formData: SystemSelectorFormProps
): BuildingFinalResultJSON => {
  const finalResultJSON: BuildingFinalResultJSON = {
    Building: {},
  };

  if (formData?.zones && formData?.zones?.length > 0) {
    formData.zones.forEach((zone) => {
      zone.data.forEach((item) => {
        finalResultJSON.Building[item?.name?.value] = {
          airHandler: item?.airHandle,
          sqft: item?.sqft,
          distance: item?.distance?.value,
          ...(item?.ignore && { ignore: item?.ignore }),
        };
      });
    });
  }

  return finalResultJSON;
};

const exItems = {
  room: '2',
  zones: [
    {
      id: 1,
      name: 'room 1',
      data: [
        {
          name: {
            label: 'Basement',
            value: 'Basement',
          },
          sqft: '250',
          distance: {
            label: '25 ft',
            value: 25,
          },
          airHandle: 'WMAH',
          ignore: false,
          other: '',
        },
      ],
    },
    {
      id: 2,
      name: 'room 2',
      data: [
        {
          name: {
            label: 'Bedroom',
            value: 'Bedroom',
          },
          sqft: '350',
          distance: {
            label: '25 ft',
            value: 25,
          },
          airHandle: 'WMAH',
          ignore: true,
          other: '',
        },
      ],
    },
  ],
  airHandler: 'WMAH',
};

const finalResultJSON = {
  Building: {
    Basement: {
      airHandler: 'WMAH',
      sqft: '250',
      distance: 25,
    },
    Bedroom: {
      airHandler: 'WMAH',
      sqft: '350',
      distance: 25,
      ignore: true,
    },
  },
};

export const stylesSelect = {
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => {
    return {
      ...provided,
      color: '#7B8C9E',
    };
  },
  clearIndicator: (provided: any) => {
    return {
      ...provided,
      color: '#7B8C9E',
    };
  },
  singleValue: (provided: any) => {
    return {
      ...provided,
      color: '#7B8C9E',
    };
  },
  control: (provided: any, state: any) => {
    return {
      ...provided,
      background: '',
      padding: '0',
      paddingTop: '.25rem',
      paddingBottom: '.25rem',
      borderRadius: '.5rem',
      borderColor: state.isFocused ? '#1C8EFF' : '#E2E8F0',
      color: '#E2E8F0',
      '&:hover': {
        color: state.isFocused ? '#E2E8F0' : '#1C8EFF',
        borderColor: state.isFocused ? '#E2E8F0' : '#1C8EFF',
      },
      minHeight: 38,
    };
  },
  menuList: (provided: any) => provided,
};

export function removeDuplicates<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}
