import { FC } from 'react';
import { MAP_TAG_BUTTONS } from 'utils/constants';
import { MapData } from '../type';
import FormatValue from 'utils/FormatValue';
import { MdLocationOn } from 'react-icons/md';
import { RenderHTML } from 'components/renderHtml';
import { Button } from '../../button';
import './partner-button.scss';

export const PartnerCard: FC<{
  data: MapData;
  onChangeOpen: (data: MapData) => void;
}> = ({ data, onChangeOpen }) => {
  const attr = data.attributes;

  const getTagClass = (name: string) => {
    switch (name) {
      case MAP_TAG_BUTTONS.franchise:
        return 'red-tag-button';
      case MAP_TAG_BUTTONS.installer:
        return 'violet-tag-button';
      case MAP_TAG_BUTTONS.online:
      case MAP_TAG_BUTTONS.inStock:
        return 'green-tag-button';
      case MAP_TAG_BUTTONS.retail:
        return 'orange-tag-button';
      case MAP_TAG_BUTTONS.dealer:
        return 'blue-tag-button';
      default:
        return;
    }
  };

  const lowesStore = attr?.title?.toLowerCase().includes('lowes') || false;

  return (
    <div className='mrc-partner-btn'>
      <span>
        <MdLocationOn className='size-8 flex-shrink-0 text-primary' />
      </span>
      <div className='flex-cols'>
        <h1 className='font-bold'>{attr?.title || 'undefined'}</h1>

        {data.dispatch && (
          <div>
            {attr?.address} {attr?.street_1} {attr?.city} {attr?.state}{' '}
            {attr?.postal_code}
          </div>
        )}
        {!data.dispatch && <div>{attr?.address || 'undefined'}</div>}

        {!data.dispatch && attr?.description && (
          <RenderHTML
            className='text-sm text-primary'
            data={attr.description as string}
          />
        )}

        <div className='text-sm text-primary'>
          {attr?.phone && (
            <div className='flex-item'>
              <span>Phone:</span>
              <a href={`tel:${attr?.phone}`}>
                {FormatValue.phoneNumber(parseInt(attr.phone))}
              </a>
            </div>
          )}

          {attr?.email && (
            <div className='flex-item'>
              <span>Email:</span>
              <a
                href={`mailto:${attr?.email}`}
                className='truncate'
                style={{ maxWidth: '225px', display: 'inline-block' }}
              >
                {attr?.email}
              </a>
            </div>
          )}

          {attr?.website && (
            <div className='flex-item'>
              <span>Web:</span>
              <a
                href={`https://${FormatValue.removeUrlProtocol(attr?.website)}`}
                target='_blank'
                className='truncate'
                style={{ maxWidth: '225px', display: 'inline-block' }}
              >
                {FormatValue.removeUrlProtocol(attr?.website)}
              </a>
            </div>
          )}

          <div className='flex-item'>
            {lowesStore && (
              <span className={getTagClass(MAP_TAG_BUTTONS.inStock)}>
                {MAP_TAG_BUTTONS.inStock}
              </span>
            )}

            {data?.attributes?.store_types?.data?.map((item, index) => (
              <span
                key={item.id || index}
                className={getTagClass(item.attributes.name)}
              >
                {item.attributes.name}
              </span>
            ))}
          </div>
        </div>

        {data.dispatch && (
          <>
            <div className='flex-item'>
              <Button
                variant='custom'
                onClick={() => onChangeOpen(data)}
                className='cta-btn'
              >
                Get custom quote
              </Button>
            </div>
            <div className='flex-item'>
              <span className={'red-tag-button'}>
                {MAP_TAG_BUTTONS.franchise}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
