import React, { useState, FC } from 'react';
import { MatchJobAddressTemplate } from 'components/form/work-order';
import {
  AddressComponents,
  PlaceAutocompleteInput,
} from './autocomplete-input';

interface Props {
  className: string;
  placeholder?: string;
  value?: string;
  onChange: (address: MatchJobAddressTemplate | null) => void;
  onBlur: () => void;
  error?: boolean;
  errorMessage?: string;
}

export const GmapPlaceAutocomplete: FC<Props> = ({
  className,
  onChange,
  error,
  errorMessage,
}) => {
  const [inputValue, setInputValue] = useState('');

  const getUnformattedAddress = (
    addressComponents: google.maps.GeocoderAddressComponent[]
  ): MatchJobAddressTemplate => {
    let streetNum, streetName;
    const address: MatchJobAddressTemplate = {
      street_1: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    };

    if (!addressComponents) address;

    addressComponents.forEach((element) => {
      const types = element.types;
      if (types.includes('street_number')) {
        streetNum = element.long_name;
      }
      if (types.includes('route')) {
        streetName = element.long_name;
      }
      if (types.includes('locality')) {
        address.city = element.long_name;
      }
      if (types.includes('administrative_area_level_1')) {
        address.state = element.short_name;
      }
      if (types.includes('postal_code')) {
        address.postal_code = element.long_name;
      }
      if (types.includes('country')) {
        address.country = element.long_name;
      }

      return;
    });

    address.street_1 = `${streetNum} ${streetName}`;

    return address;
  };

  const handleAddressChange = (address: AddressComponents) => {
    setInputValue(address.address);
    onChange(getUnformattedAddress(address.addressComponents));
  };

  return (
    <div className='relative flex w-full flex-col'>
      <PlaceAutocompleteInput
        className={className}
        addressFromInput={handleAddressChange}
      />
      {error && errorMessage && (
        <div className='mb-2 text-xs text-red-500'>{errorMessage}</div>
      )}
    </div>
  );
};
