import { FC, useEffect, useState } from 'react';

export const RenderHTML: FC<{
  data: string;
  className?: string;
  tagType?: 'article' | 'div' | string;
  suppressHydrationWarning?: boolean;
}> = ({
  data,
  className = '',
  suppressHydrationWarning = false,
  tagType = 'div',
}) => {
  const [sanitizedHTML, setSanitizedHTML] = useState<string>('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const DOMPurify = require('dompurify');
      setSanitizedHTML(DOMPurify.sanitize(data));
    }
  }, [data]);

  switch (tagType) {
    case 'article':
      return (
        <article
          suppressHydrationWarning={suppressHydrationWarning}
          className={className}
          dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
        />
      );
    default:
      return (
        <div
          suppressHydrationWarning={suppressHydrationWarning}
          className={className}
          dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
        />
      );
  }
};
